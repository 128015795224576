import React from "react";

function DueCard({ color, title, content }) {
  return (
    <div className="card">
      <div className="card-header p-3 pt-2">
        <div
          className={`bg-${color} shadow-${color} text-center text-white text-bold border-radius-xl p-1 `}
        >
          {title}
          {/* <i className="material-icons opacity-10">{icon}</i> */}
        </div>
        <div className="text-center p-4">
          <h4 className="mb-0">{content}</h4>
        </div>
      </div>
      <hr className="dark horizontal my-0" />
    </div>
  );
}

export default DueCard;
