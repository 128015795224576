import React from 'react';
import { useForm } from 'react-hook-form';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import auth from '../../services/authService';

function AddChoreModal({ show, setShow }) {
  const { register, handleSubmit, reset } = useForm();

  const handleClose = () => setShow(false);

  const onSubmit = async (data) => {
    // Construct the chore object
    const choreObject = {
      name: data.choreName,
      Feedback: data.feedback?.split('\n').filter((line) => line.trim() !== '') || [],
    };

    // Send the chore object to the backend
    await auth.addChore(choreObject);

    handleClose();
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add a New Chore</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(onSubmit)} onReset={reset} id="formPlaintextChore">
          <Modal.Body>
            <Form.Group as={Row} controlId="formPlaintextChoreName">
              <Form.Label column sm="3">
                Chore Name
              </Form.Label>
              <Form.Control type="text" name="choreName" placeholder="Enter chore name" {...register('choreName')} />
            </Form.Group>

            <Form.Group as={Row} controlId="formPlaintextFeedback">
              <Form.Label column sm="2">
                Feedback
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="feedback"
                placeholder="Enter feedback, each item on a new line"
                {...register('feedback')}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" type="submit" form="formPlaintextChore">
              Save Changes
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

export default AddChoreModal;
