import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useOutletContext } from 'react-router-dom';
import GCard from '../utils/GCard';
import EmptyRoomTable from '../utils/EmptyRoomTable';
import HouseCard from '../utils/HouseCard';
import RoomTable from '../utils/RoomTable';
import { useForm, Controller } from 'react-hook-form';
import auth from '../../services/authService';
import { toast } from 'react-toastify';
import { useStateValue } from '../../context/StateProvider';
import Select from 'react-select';
import ImageUpload from './ImageUpload';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import '../../css/imageSelect.css';
import { Tab, Tabs } from '@mui/material';
import AdRoomTable from '../utils/AdRoomTable';
import AddChoreModal from './AddChoreModal';

function AdminMain() {
  const [{ user }] = useStateValue();
  const [housesList, tenantsList] = useOutletContext();
  const [openModal, setOpenModal] = useState(false);
  const [openModalChore, setOpenModalChore] = useState(false);
  const [choresList, setChoresList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [roomAdvertsList, setRoomAdvertsList] = useState([]);
  const [singleBathroom, setSingleBathroom] = useState(true);
  const [imagePreview, setImagePreview, handleChangeImage, uploadHouseImage] = ImageUpload();

  const [emptyRooms] = useState(tenantsList?.filter((tenant) => tenant.is_empty === true));
  const [view, setView] = useState(true);

  const { register, handleSubmit, reset, control } = useForm();

  useEffect(() => {
    async function getChores() {
      await auth.getAllChores().then((res) => {
        const chore_array = res.data;
        chore_array.forEach((element, index) => {
          chore_array[index] = { value: element._id, label: element.name };
        });
        setChoresList(chore_array);
      });
    }
    async function getRoomAdverts() {
      await auth.getAllAds().then((res) => {
        setRoomAdvertsList(res.data);
      });
    }
    getRoomAdverts();
    getChores();
  }, []);

  async function onSubmit(data) {
    setLoading(true);
    let image = null;
    try {
      image = await uploadHouseImage();
    } catch (err) {
      console.log(err);
      return;
    }
    if (!image) {
      alert('Should specify an image!');
      return;
    }

    const url = image.data.url.split('/upload');

    data = { ...data, url: url[0] + '/upload/c_scale,h_511,w_681' + url[1] };

    if (!singleBathroom) {
      data.bathrooms = [
        { numTenants: data.bathroomFirstNumTenants, name: data.bathroomFirstDesc },
        { numTenants: data.bathroomSecondNumTenants, name: data.bathroomSecondDesc },
      ];
      data.numTenants = Number(data.bathroomFirstNumTenants) + Number(data.bathroomSecondNumTenants);
      // Remove bathroom chore from general chores
      data.selected_chores = data.selected_chores.filter((chore) => chore !== '62251c6234ee7d170d7220c0');
    }

    await auth
      .addHouse(data, user._id)
      .then(() => {
        toast.success('house added');
        setOpenModal(false);
        setImagePreview(null);
        setLoading(false);
      })
      .catch((err) => console.log(err.message));
  }
  const [value, setValue] = useState('one');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <Tabs variant="fullWidth" value={value} onChange={handleChange} centered>
        <Tab className="col-6" value="one" label="House View" onClick={() => setView(true)} />
        <Tab className="col-6" value="two" label="Room View" onClick={() => setView(false)} />
      </Tabs>

      {view ? (
        <GCard header="Houses">
          <div className="row">
            <div className="col-6">
              <div className="text-center">
                <Button onClick={() => setOpenModal(true)} className="bg-info shadow-info">
                  Add A House
                </Button>
              </div>
            </div>
            <div className="col-6">
              <div className="text-center">
                <Button onClick={() => setOpenModalChore(true)} className="bg-info shadow-info">
                  Add A Chore
                </Button>
              </div>
            </div>
          </div>
          <div className="row m-4">
            {housesList.map((house, i) => (
              <div key={i} className="col-xl-3 col-md-6 mb-xl-0 mb-4">
                <HouseCard house={house} />
              </div>
            ))}
          </div>
        </GCard>
      ) : (
        <>
          {emptyRooms.length ? (
            <div className="pb-2">
              <GCard header="Empty Rooms">
                <EmptyRoomTable rooms={emptyRooms} />
              </GCard>
            </div>
          ) : (
            <div>No empty rooms</div>
          )}
          {roomAdvertsList.length ? (
            <div className="pb-2">
              <GCard header="Advertised Rooms">
                <AdRoomTable rooms={roomAdvertsList} />
              </GCard>
            </div>
          ) : (
            <div>No Ad rooms</div>
          )}
          {housesList.map((house, i) => {
            const roomsForHouse = tenantsList.filter((tenant) => tenant.house?._id === house?._id);
            return (
              <GCard key={i} header={house.house_name}>
                <div className="row m-4">
                  <RoomTable rooms={roomsForHouse} />
                </div>
              </GCard>
            );
          })}
        </>
      )}

      <Modal
        show={openModal}
        onHide={() => {
          setOpenModal(false);
          setImagePreview(null);
        }}
        backdrop="static"
        keyboard={false}
        className="fade"
      >
        <Modal.Header closeButton>
          <Modal.Title>add a new house</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form id="form1" className="m-4" onSubmit={handleSubmit(onSubmit)} onReset={reset}>
            <Row>
              <Form.Group className="col-md-4 mb-3" controlId="formGridHouseName">
                <Form.Label>House Name</Form.Label>
                <Form.Control {...register('house_name')} name="house_name" placeholder="House Name" />
              </Form.Group>

              <Form.Group className="col-md-4 mb-3" controlId="formGridAddress">
                <Form.Label>Address</Form.Label>
                <Form.Control {...register('address')} name="address" placeholder="Address" />
              </Form.Group>

              <Form.Group className="col-md-4 mb-3" controlId="formGridPostal">
                <Form.Label>Postal Code</Form.Label>
                <Form.Control {...register('postal_code')} name="postal_code" placeholder="Postal Code" />
              </Form.Group>
            </Row>

            <Row>
              <Form.Group className="col-md-4 mb-3" controlId="formGridHouseCode">
                <Form.Label>House Code</Form.Label>
                <Form.Control {...register('house_code')} name="house_code" placeholder="House Code" />
              </Form.Group>

              <Form.Group className="col-md-4 mb-3" controlId="formGridWifiName">
                <Form.Label>Wifi Name</Form.Label>
                <Form.Control {...register('wifi_name')} name="wifi_name" placeholder="WIFI Name" />
              </Form.Group>

              <Form.Group className="col-md-4 mb-3" controlId="formGridWifiPassword">
                <Form.Label>Wifi Password</Form.Label>
                <Form.Control {...register('wifi_password')} name="wifi_password" placeholder="WIFI Password" />
              </Form.Group>
            </Row>
            <Row className="raw-with-margin">
              <Form.Group className="col-lg-12 mx-auto" controlId="formGridNumBathrooms">
                <Form.Label>Number of Bathrooms</Form.Label>
                <Form.Check
                  type="radio"
                  id="single"
                  label="Single"
                  value="single"
                  checked={singleBathroom}
                  onChange={() => setSingleBathroom(true)}
                />

                <Form.Check
                  type="radio"
                  id="multiple"
                  label="Multiple"
                  value="multiple"
                  checked={!singleBathroom}
                  onChange={() => setSingleBathroom(false)}
                />
              </Form.Group>
            </Row>
            <Row className="raw-with-margins">
              {singleBathroom && (
                <Form.Group className="col-md-4 mb-3" controlId="formGridNuRooms">
                  <Form.Label>Number of Rooms</Form.Label>
                  <Form.Control
                    {...register('numTenants')}
                    name="numTenants"
                    type="number"
                    step="1"
                    placeholder="Number of rooms"
                  />
                </Form.Group>
              )}
              <Form.Group className="col-8" controlId="formGridNuRooms">
                <Form.Label>Select Wanted Chores</Form.Label>
                <Controller
                  control={control}
                  name="selected_chores"
                  render={({ field: { onChange, ref } }) => (
                    <Select
                      inputRef={ref}
                      classNamePrefix="addl-class"
                      options={choresList}
                      isMulti
                      onChange={(val) => onChange(val.map((c) => c.value))}
                    />
                  )}
                />
              </Form.Group>
            </Row>
            {!singleBathroom && (
              <>
                <Row>
                  <Form.Group className="col-8" controlId="formGridBathroomFirst">
                    <Form.Label>First bathroom short description</Form.Label>
                    <Form.Control
                      {...register('bathroomFirstDesc')}
                      name="bathroomFirstDesc"
                      placeholder="Down stairs to the left"
                    />
                  </Form.Group>
                  <Form.Group className="col-md-4 mb-3" controlId="formGridBathroomFirst">
                    <Form.Label>Number of Rooms</Form.Label>
                    <Form.Control
                      {...register('bathroomFirstNumTenants')}
                      name="bathroomFirstNumTenants"
                      type="number"
                      step="1"
                      placeholder="Number of rooms"
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group className="col-8" controlId="formGridBathroomSecond">
                    <Form.Label>Second bathroom short description</Form.Label>
                    <Form.Control
                      {...register('bathroomSecondDesc')}
                      name="bathroomSecondDesc"
                      placeholder="Down stairs to the left"
                    />
                  </Form.Group>
                  <Form.Group className="col-md-4 mb-3" controlId="formGridBathroomSecond">
                    <Form.Label>Number of Rooms</Form.Label>
                    <Form.Control
                      {...register('bathroomSecondNumTenants')}
                      name="bathroomSecondNumTenants"
                      type="number"
                      step="1"
                      placeholder="Number of rooms"
                    />
                  </Form.Group>
                </Row>
              </>
            )}

            <Row></Row>

            <Row className="py-4">
              <div className="col-lg-12 mx-auto">
                <div className="input-group mb-3 px-2 py-2 rounded-pill bg-white shadow-sm">
                  <label id="upload-label" htmlFor="upload" className="form-label font-weight-light text-muted">
                    Upload an image for the house
                  </label>
                  <input className="form-control border-0" type="file" id="upload" onChange={handleChangeImage} />
                  <div className="z-index-0">
                    <label htmlFor="upload" className="btn btn-light m-0 rounded-pill px-4">
                      <CloudUploadIcon className="text-muted" />
                      <small className="text-uppercase font-weight-bold text-muted">Choose file</small>
                    </label>
                  </div>
                </div>
                <p className="mt-5 font-italic text-black text-sm text-center">
                  The image uploaded will be rendered inside the box below.
                </p>
                <div className="image-area border-info mt-4">
                  {imagePreview && (
                    <img
                      id="imageResult"
                      className="img-fluid rounded shadow-sm mx-auto d-block"
                      src={imagePreview}
                      alt=""
                    />
                  )}
                </div>
              </div>
            </Row>
            <Row></Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setOpenModal(false);
              setImagePreview(null);
            }}
          >
            Close
          </Button>
          <Button disabled={loading} variant="info" type="submit" form="form1">
            Add house
          </Button>
        </Modal.Footer>
      </Modal>
      <AddChoreModal show={openModalChore} setShow={setOpenModalChore} />
    </>
  );
}

export default AdminMain;
