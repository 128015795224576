import React, { useEffect } from 'react';
import { useStateValue } from '../../context/StateProvider';
import RoomieInfo from './RoomieInfo';

function HorizontalCards() {
  const [{ user }] = useStateValue();

  const pointChart = () => {
    // setPointChartData({
    //   labels: ["Best Roomie", "You"],
    //   datasets: [
    //     {
    //       backgroundColor: [
    //         "rgba(75, 192, 192, 0.7)",
    //         "rgba(255, 205, 86, 0.7)",
    //         "rgba(255, 99, 132, 0.7)",
    //       ],
    //       borderColor: "rgba(0,0,0,1)",
    //       borderWidth: 2,
    //       data: [user.house.best_roomie, user.points],
    //     },
    //   ],
    // });
  };

  useEffect(() => {
    pointChart();
  }, []);

  return (
    <div>
      <div className="mb-4">
        <RoomieInfo />
      </div>

      {/* <GCard header="House Chores For This Week"> */}
      <div className="card h-100 mb-4">
        <div className="card-header pb-0 p-3">
          <h6 className="mb-0">House Chores For This Week</h6>
        </div>

        <div className="card-body p-3">
          <div className="table-responsive p-0">
            <table className="table align-items-center mb-0">
              <thead className="thead-light">
                <tr>
                  <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Roomie</th>
                  <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">This Week</th>
                  {/* <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                    Permanent
                  </th> */}
                </tr>
              </thead>

              <tbody>
                {user.house.weekly_chores_0.map((chore) => (
                  <tr key={chore.who._id}>
                    <td>{chore.who.first_name} </td>
                    <td>{chore.chore.map((chore) => chore.name + ', ')}</td>
                    {/* <td>{chore.who.chore_permanent}</td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="card h-100 mb-4">
        <div className="card-header pb-0 p-3">
          <h6 className="mb-0">Comments from the Admin</h6>
        </div>

        <div className="card-body p-3">
          <ul className="list-group">
            {user.admin_com ? (
              user.admin_com.split('**').map((com, i) => (
                <li className="list-group-item border-0 ps-0 text-sm" key={i}>
                  <strong className="text-dark">{i + 1 + ': '}</strong>

                  {com}
                </li>
              ))
            ) : (
              <li className="list-group-item border-0 ps-0 text-sm">No comments</li>
            )}
          </ul>
        </div>
      </div>

      {/* <div className="card h-100 mb-4">
        <div className="card-header pb-0 p-3">
          <h6 className="mb-0">Points Insight</h6>
        </div>

        <div className="card-body p-3">
          <HorizontalBar
            data={pointChartData}
            width={null}
            height={null}
            options={{
              legend: {
                display: false,
              },
              scales: {
                xAxes: [
                  {
                    ticks: {
                      beginAtZero: true,
                    },
                  },
                ],
              },
            }}
          />
        </div>
      </div> */}
    </div>
  );
}

export default HorizontalCards;
