import httpService from './httpService';
import decode from 'jwt-decode';

const apiEndpoint = '/api/login';
const apiGetRoomie = '/api/roomies';
const apiGetHouse = '/api/houses';
const apiGetHomeOwners = '/api/homeowners';
const apiGetNewRoomie = '/api/newroomies';
const apiPreviousRoomies = '/api/previousroomies';
const apiGetChore = '/api/chores';
const apiGetImages = '/api/uploadimages';
const apiGetAdmins = '/api/admins';
const apiGetAds = '/api/wds'; // when you name this as ads it get blocked by adblock
// const apiGetAdmins = "/api/admins";
// const apiCloudinaryUpload =
//   "https://api.cloudinary.com/v1_1/sweepnsleep-com/image/upload";

const tokenKey = 'token';
httpService.setJwt(getJwt());

async function login(email, password, account_type) {
  const res = await httpService.post(apiEndpoint, {
    email,
    password,
    account_type,
  });
  const user = res.data.user;
  const jwt = res.data.token;
  localStorage.setItem(tokenKey, jwt);
  return user;
}

async function getRoomie() {
  return await httpService.get(apiGetRoomie);
}

async function getDamageDepositData(houseId) {
  return await httpService.get(`${apiGetRoomie}/damage_deposit_data/${houseId}`);
}

async function getAllChores() {
  return await httpService.get(apiGetChore);
}

// add a new chore to the database
async function addChore(chore) {
  return await httpService.post(apiGetChore, chore);
}

async function getPreviousRoomie(id) {
  return await httpService.get(`${apiPreviousRoomies}/${id}`);
}

async function addRoomie(roomie) {
  return await httpService.post(apiGetRoomie, roomie);
}

async function resetPassword(id) {
  return await httpService.put(`${apiGetRoomie}/resetpassword/${id}`);
}

async function saveDamageDepositData(id, data) {
  return await httpService.put(`${apiGetRoomie}/damage_deposit_data/${id}`, {
    returned_damaged_deposit: data?.returned_damaged_deposit || false,
    returned_damaged_form_description: data?.returned_damaged_form_description || '',
    returned_damaged_deposit_date: data?.returned_damaged_deposit_date || new Date(),
    comments: data?.comments || '',
  });
}

async function editRoomie(roomie, id) {
  await httpService.put(`${apiGetHouse}/bestworst/${roomie.house}`, {
    points: roomie.points,
    id: id,
  });
  return await httpService.put(`${apiGetRoomie}/${id}`, roomie);
}

async function editRoomInfo(roomie, id) {
  return await httpService.put(`${apiGetRoomie}/roominfo/${id}`, roomie);
}

async function markVacant(id, home_owner_id) {
  return await httpService.put(`${apiGetRoomie}/markvacant/${id}`, {
    home_owner_id: home_owner_id,
  });
}

async function editRoomNew(roomie, id) {
  return await httpService.put(`${apiGetRoomie}/roomnew/${id}`, roomie);
}

async function addHouse(house, id) {
  return await httpService.post(`${apiGetHouse}/${id}`, house);
}

async function editHouse(house, id) {
  return await httpService.put(`${apiGetHouse}/${id}`, house);
}

async function addVariableCosts(variable_costs, id) {
  return await httpService.put(`${apiGetHouse}/variablecosts/${id}`, variable_costs);
}

async function getVariableCosts(houseId, year) {
  return await httpService.get(`${apiGetHouse}/variablecosts/${houseId}/${year}`);
}

async function getVariableCostsAverages(houseId) {
  return await httpService.get(`${apiGetHouse}/averages/variablecosts/${houseId}`);
}

async function editRoomieProfile(roomie, id) {
  return await httpService.put(`${apiGetRoomie}/profile/${id}`, roomie);
}

async function addRoomieFeed(feed, id) {
  return await httpService.post(`${apiGetRoomie}/feed/${id}`, feed);
}

async function addRoomieSupplies(roomie, id) {
  return await httpService.put(`${apiGetRoomie}/supplies/${id}`, roomie);
}

async function addRoomieScd(roomie, id) {
  return await httpService.put(`${apiGetRoomie}/showings/${id}`, roomie);
}

async function getRoomieScd(id) {
  return await httpService.get(`${apiGetRoomie}/showings/${id}`);
}

async function getAllRoomieScd() {
  return await httpService.get(`${apiGetRoomie}/showings/`);
}

async function getAllRoomies() {
  return await httpService.get(`${apiGetRoomie}/all`);
}

async function getOwnerRoomies(houses_array) {
  return await httpService.post(`${apiGetRoomie}/ownerroomies`, {
    houses: houses_array,
  });
}

async function addAgreementToRoomie(data) {
  return await httpService.post(`${apiGetRoomie}/addagreementtoroomie/${data.room}`, {
    data: data,
  });
}

async function getAllNewRoomies(id) {
  return await httpService.get(`${apiGetNewRoomie}/all/${id}`);
}

async function addNewRoomie(newroomie, homeowner_id) {
  return await httpService.post(`${apiGetNewRoomie}/${homeowner_id}`, newroomie);
}

async function editNewRoomie(newroomie, id) {
  return await httpService.put(`${apiGetNewRoomie}/${id}`, newroomie);
}

async function getNewRoomie(id) {
  return await httpService.get(`${apiGetNewRoomie}/${id}`);
}

async function getRoomieFromHouse(id) {
  return await httpService.get(`${apiGetRoomie}/house/${id}`);
}

async function signLease(uid, agid) {
  return await httpService.put(`${apiGetRoomie}/leasetemplate/${uid}/${agid}`, {
    did_sign_lease: 1,
  });
}

async function verifyFeedback(rId, fId, point, ps, whoId) {
  return await httpService.put(`${apiGetRoomie}/verifyfeed/${rId}`, {
    feed_id: fId,
    who_id: whoId,
    point: ps ? 0 : point,
    strike: ps ? point : 0,
  });
}

async function deleteFeedback(rId, fId) {
  return await httpService.put(`${apiGetRoomie}/deletefeed/${rId}`, {
    feed_id: fId,
  });
}

async function addPayment(rId, payment) {
  return await httpService.put(`${apiGetRoomie}/addpayment/${rId}`, {
    payment: payment,
  });
}

async function addAgreement(homeowner_id, agreement) {
  return await httpService.put(`${apiGetHomeOwners}/addagreement/${homeowner_id}`, {
    agreement: agreement,
  });
}

async function removeAgreement(homeowner_id, agreement) {
  return await httpService.put(`${apiGetHomeOwners}/deleteagreement/${homeowner_id}`, {
    agreement: agreement,
  });
}

async function addRoom(homeowner_id, house_id, room_name) {
  return await httpService.put(`${apiGetHomeOwners}/addroom/${homeowner_id}`, {
    house_id: house_id,
    room_name: room_name,
  });
}

async function getAgreement(home_id, agreement_id) {
  return await httpService.put(`${apiGetHomeOwners}/getagreement/${home_id}`, {
    agreement_id: agreement_id,
  });
}

async function getAllAgreements(roomie_id) {
  return await httpService.get(`${apiGetRoomie}/getallagreements/${roomie_id}`);
}

async function editagreement(roomie_id, agreement_id, type) {
  return await httpService.put(`${apiGetRoomie}/editagreement/${roomie_id}`, {
    agreement_id: agreement_id,
    type: type,
  });
}

async function feedbackAppeal(appeal) {
  return await httpService.put(`${apiGetRoomie}/feedappeal/`, {
    first_name: appeal.first_name,
    last_name: appeal.last_name,
    chore_name: appeal.chore_name,
    chore_date: appeal.chore_date,
    appeal: appeal.appeal,
    email: appeal.email,
    feedback: appeal.feedback,
  });
}

async function ruleChangeRequest(request) {
  return await httpService.put(`${apiGetRoomie}/rulechange/`, {
    first_name: request.first_name,
    last_name: request.last_name,
    rule: request.rule,
    why_not: request.why_not,
    suggestion: request.suggestion,
    email: request.email,
  });
}

async function prereleaseLease(data, uid) {
  return await httpService.put(`${apiGetRoomie}/prerelease/${uid}`, data);
}

async function releaseLease(uid) {
  return await httpService.put(`${apiGetRoomie}/releaselease/${uid}`);
}

async function verifySupply(rId, sId, price) {
  return await httpService.put(`${apiGetRoomie}/verifysupply/${rId}`, {
    supply_id: sId,
    price_added: price,
  });
}

async function deleteSupply(rId, sId) {
  return await httpService.put(`${apiGetRoomie}/deletesupply/${rId}`, {
    supply_id: sId,
  });
}

async function getAllHouses() {
  return await httpService.get(`${apiGetHouse}/all`);
}

async function getOwnerHouses(houses_array) {
  return await httpService.post(`${apiGetHouse}/ownerhouses`, {
    houses: houses_array,
  });
}

async function getEmptyRoomes() {
  return await httpService.get(`${apiGetRoomie}/emptyrooms`);
}

async function getAllHouseSupplies(hId) {
  return await httpService.get(`${apiGetRoomie}/allsupplies/${hId}`);
}

async function getAllHouseSuppliesAmount(hId) {
  return await httpService.get(`${apiGetRoomie}/verifiedsupplies/${hId}`);
}

async function getAllHomeOwners() {
  return await httpService.get(`${apiGetHomeOwners}/all`);
}

async function getHomeOwner(hoid) {
  return await httpService.get(`${apiGetHomeOwners}/${hoid}`);
}

async function getAllHomeownerApplicants() {
  return await httpService.get(`${apiGetAdmins}/allapplicants`);
}

async function addHomeOwner(homeowner) {
  return await httpService.post(apiGetHomeOwners, homeowner);
}

async function editHomeOwner(id, homeowner) {
  return await httpService.put(`${apiGetHomeOwners}/${id}`, homeowner);
}

async function editHomeOwnerProfile(homeowner, id) {
  return await httpService.put(`${apiGetHomeOwners}/profile/${id}`, homeowner);
}

async function editAdminProfile(admin, id) {
  return await httpService.put(`${apiGetAdmins}/profile/${id}`, admin);
}

export function deleteHomeOwner(id) {
  return httpService.delete(`${apiGetHomeOwners}/${id}`);
}

export function deleteHome(id, homeowner_id) {
  return httpService.delete(`${apiGetHouse}/${id}`, {
    data: { homeowner_id: homeowner_id },
  });
}

async function updateSupplyBoard(hId, supply) {
  return await httpService.put(`${apiGetHouse}/updatesupplyboard/${hId}`, {
    supply: supply,
  });
}

async function getAllRoomieFeeds(rId) {
  return await httpService.get(`${apiGetRoomie}/feed/${rId}`);
}

export function deleteRoomie(id) {
  return httpService.delete(`${apiGetRoomie}/${id}`);
}

// export function uploadImage(image) {
//   const formData = new FormData();
//   formData.append("file", image);
//   formData.append("upload_preset", process.env.UPLOAD_PRESET);

//   return httpService.post(apiCloudinaryUpload, formData);
// }

async function uploadImage(image) {
  const imageJson = JSON.stringify({ data: image });
  return await httpService.post(apiGetImages, imageJson, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

async function uploadMultiImage(image, id) {
  const imageJson = JSON.stringify({ data: image });

  return await httpService
    .post(`${apiGetImages}/rooms/${id}`, imageJson, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .catch((error) => {
      console.log(error);
    });
}

// post ads to db
async function postAd(ad) {
  return await httpService.post(apiGetAds, ad);
}

// push a houseowner application to the admin database
async function postHouseOwnerApplication(application) {
  return await httpService.post(`${apiGetAdmins}/addhomeownerapplicant`, application);
}

// get all ads from db
async function getAllAds() {
  return await httpService.get(apiGetAds);
}

// delete ad from db
async function deleteAd(id) {
  console.log(id);
  return await httpService.delete(`${apiGetAds}/${id}`);
}

async function adApplicant(applicant) {
  return await httpService.put(`${apiGetAds}/addapplicant`, applicant);
}

function logout() {
  localStorage.removeItem(tokenKey);
}

function getJwt() {
  return localStorage.getItem(tokenKey);
}

function checkExpJwt() {
  const token = localStorage.getItem(tokenKey);
  if (!token) return false;

  try {
    const { exp } = decode(token);
    if (exp < new Date().getTime() / 1000) {
      return false;
    }
  } catch (e) {
    return false;
  }

  return true;
}

function getUserRole() {
  const token = localStorage.getItem(tokenKey);
  if (!token) return false;

  try {
    const { role } = decode(token);
    if (!role) {
      return false;
    }
    return role;
  } catch (e) {
    return false;
  }
}

const functions = {
  login,
  logout,
  getUserRole,
  getJwt,
  getRoomie,
  getDamageDepositData,
  saveDamageDepositData,
  getAllRoomies,
  getAllHouses,
  addRoomie,
  deleteRoomie,
  editRoomie,
  signLease,
  editRoomieProfile,
  addRoomieFeed,
  addRoomieSupplies,
  verifyFeedback,
  getAllHouseSupplies,
  getAllRoomieFeeds,
  verifySupply,
  addHouse,
  editHouse,
  addVariableCosts,
  getVariableCosts,
  getVariableCostsAverages,
  deleteFeedback,
  deleteSupply,
  addRoomieScd,
  getRoomieScd,
  getAllRoomieScd,
  getAllHouseSuppliesAmount,
  checkExpJwt,
  releaseLease,
  feedbackAppeal,
  ruleChangeRequest,
  getAllNewRoomies,
  addNewRoomie,
  editNewRoomie,
  getNewRoomie,
  getRoomieFromHouse,
  editRoomInfo,
  addPayment,
  editRoomNew,
  getPreviousRoomie,
  getOwnerRoomies,
  getOwnerHouses,
  getAllHomeOwners,
  getHomeOwner,
  addHomeOwner,
  deleteHomeOwner,
  uploadImage,
  editHomeOwner,
  deleteHome,
  getAllChores,
  addAgreement,
  removeAgreement,
  addAgreementToRoomie,
  getEmptyRoomes,
  uploadMultiImage,
  postAd,
  getAllAds,
  deleteAd,
  adApplicant,
  postHouseOwnerApplication,
  getAllHomeownerApplicants,
  getAgreement,
  getAllAgreements,
  updateSupplyBoard,
  editHomeOwnerProfile,
  editagreement,
  markVacant,
  prereleaseLease,
  addRoom,
  editAdminProfile,
  addChore,
  resetPassword,
};

export default functions;
